import { useState } from "react";
import Header from "./components/Header.jsx";
import Earth from "./Globe.jsx";

function App() {
	const [selectedCategory, setSelectedCategory] = useState("Trending Topics");

	return (
		<div>
			<Header onCategoryChange={setSelectedCategory} />
			<Earth category={selectedCategory} />
		</div>
	);
}

export default App;