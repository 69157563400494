// globe.jsx
import React, { useEffect, useState, useRef } from "react";
import Globe from "react-globe.gl";
import { hudStyles, popupStyles } from "./styles";

const formatNumber = (number) =>
	number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "N/A";

function Earth() {
	const [data, setData] = useState([]);
	const [conflictData, setConflictData] = useState([]);
	const [cyberData, setCyberData] = useState([]);
	const [environmentData, setEnvironmentData] = useState([]);
	const [newsData, setNewsData] = useState([]);
	const [economicData, setEconomicData] = useState([]);
	const [infrastructureData, setInfrastructureData] = useState([]);
	const [selectedPoint, setSelectedPoint] = useState(null);
	const [relatedPoints, setRelatedPoints] = useState([]);
	const [contextParagraph, setContextParagraph] = useState("");
	const [relatedHeader, setRelatedHeader] = useState("");
	const [mainConflictPoint, setMainConflictPoint] = useState(null);
	const globeRef = useRef();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		fetch("/country_profiles.json")
			.then((response) => response.json())
			.then((jsonData) => setData(jsonData))
			.catch((error) => console.error("Error loading country data:", error.message));

		fetch("/conflict.json")
			.then((response) => response.json())
			.then((jsonData) => setConflictData(jsonData))
			.catch((error) => console.error("Error loading conflict data:", error.message));

		fetch("/cyberattacks.json")
			.then((response) => response.json())
			.then((jsonData) => setCyberData(jsonData))
			.catch((error) => console.error("Error loading cyberattack data:", error.message));

		fetch("/environment.json")
			.then((response) => response.json())
			.then((jsonData) => {
				const transformed = jsonData.map((e) => ({
					geometry: { coordinates: e.coordinates },
					properties: {
						event_name: e.event_name,
						location: e.location,
						overview: e.overview,
					},
				}));
				setEnvironmentData(transformed);
			})
			.catch((error) => console.error("Error loading environment data:", error.message));

		fetch("/news.json")
			.then((response) => response.json())
			.then((jsonData) => setNewsData(jsonData))
			.catch((error) => console.error("Error loading news data:", error.message));

		fetch("/economicdata.json")
			.then((response) => response.json())
			.then((jsonData) => setEconomicData(jsonData))
			.catch((error) => console.error("Error loading economic data:", error.message));

		// Fetch and transform infrastructure data from ports.json
		fetch("/ports.json")
			.then((response) => response.json())
			.then((jsonData) => {
				const transformed = jsonData.ports.map((port) => ({
					geometry: { coordinates: [port.coordinates.longitude, port.coordinates.latitude] },
					properties: {
						infrastructure_title: port.name,
						summary: port.overview,
						port_rank: port.rank,
						port_unit: port.volume.unit,
						port_volume_2022: port.volume["2022"],
						port_volume_2023: port.volume["2023"]
					},
				}));
				setInfrastructureData(transformed);
			})
			.catch((error) => console.error("Error loading infrastructure data:", error.message));
	};

	const handlePointClick = (point) => {
		if (!point || !point.properties) return;

		setSelectedPoint(point);
		const newRelatedPoints = point.properties?.related_points || [];

		if (point.properties.is_main_conflict) {
			setMainConflictPoint(point);
			setContextParagraph("");
			setRelatedHeader(point.properties.title || "");
			setRelatedPoints(newRelatedPoints);
		} else if (relatedPoints.includes(point)) {
			setContextParagraph(point.properties?.context || "No additional context available.");
			setRelatedHeader(point.properties.title || "");

			if (newRelatedPoints.length > 0) {
				setRelatedPoints(newRelatedPoints);
			} else {
				if (mainConflictPoint) setRelatedPoints([mainConflictPoint, point]);
				else setRelatedPoints([point]);
			}
		} else {
			setContextParagraph("");
			setRelatedHeader("");
			setRelatedPoints([]);
		}

		const selectPoint = (arr) =>
			arr.map((p) => (p === point ? { ...p, selected: true } : { ...p, selected: false }));

		setData((prevData) => selectPoint(prevData));
		setConflictData((prevData) => selectPoint(prevData));
		setCyberData((prevData) => selectPoint(prevData));
		setEnvironmentData((prevData) => selectPoint(prevData));
		setNewsData((prevData) => selectPoint(prevData));
		setEconomicData((prevData) => selectPoint(prevData));
		setInfrastructureData((prevData) => selectPoint(prevData));
	};

	const renderContent = () => {
		if (!selectedPoint) return null;
		const properties = selectedPoint.properties;

		if (properties?.is_main_conflict) {
			const { conflict_name, start_date, recent_activity, background } = properties?.details || {};
			return (
				<div>
					<div><strong>Conflict Name:</strong> {conflict_name || "N/A"}</div>
					<div><strong>Start Date:</strong> {start_date || "N/A"}</div>
					<div>
						<strong>Recent Activity:</strong>{" "}
						{recent_activity?.length
							? recent_activity.map((item, index) => <div key={index}>{item.date}: {item.event}</div>)
							: "No recent activity"}
					</div>
					<div><strong>Background:</strong> {background || "N/A"}</div>
				</div>
			);
		}

		if (relatedPoints.includes(selectedPoint)) {
			return <div><strong>Context:</strong> {contextParagraph || "No additional context available."}</div>;
		}

		// Cyber
		if (
			properties?.background_paragraph &&
			properties?.overview_of_the_hack &&
			properties?.damage_assessments_and_fallout &&
			properties?.security_measures
		) {
			return (
				<div>
					<div><strong>Title:</strong> {properties.title || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Background:</strong><br />{properties.background_paragraph || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Overview of the Hack:</strong><br />{properties.overview_of_the_hack || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Damage Assessments & Fallout:</strong><br />{properties.damage_assessments_and_fallout || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Security Measures:</strong><br />{properties.security_measures || "N/A"}</div>
				</div>
			);
		}

		// Environment
		if (properties?.event_name && properties?.overview) {
			return (
				<div>
					<div><strong>Event Name:</strong> {properties.event_name || "N/A"}</div>
					<div><strong>Location:</strong> {properties.location || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Overview:</strong><br />{properties.overview || "N/A"}</div>
				</div>
			);
		}

		// Economic
		if (properties?.economic_title && properties?.details) {
			return (
				<div>
					<div><strong>Title:</strong> {properties.economic_title || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Details:</strong><br />{properties.details || "N/A"}</div>
				</div>
			);
		}

		// Infrastructure (Ports)
		if (properties?.infrastructure_title && properties?.summary && properties?.port_rank !== undefined) {
			return (
				<div>
					<div><strong>Name:</strong> {properties.infrastructure_title || "N/A"}</div>
					<div><strong>Rank:</strong> {properties.port_rank || "N/A"}</div>
					<div><strong>Unit:</strong> {properties.port_unit || "N/A"}</div>
					<div><strong>2022 Volume:</strong> {properties.port_volume_2022 || "N/A"}</div>
					<div><strong>2023 Volume:</strong> {properties.port_volume_2023 || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Overview:</strong><br />{properties.summary || "N/A"}</div>
				</div>
			);
		}

		// News
		if (properties?.title && properties?.paragraph) {
			return (
				<div>
					<div><strong>Title:</strong> {properties.title || "N/A"}</div>
					<div style={{ marginTop: "10px" }}><strong>Details:</strong><br />{properties.paragraph || "N/A"}</div>
				</div>
			);
		}

		// Default Country Info
		return (
			<div>
				<div><strong>Population:</strong> {formatNumber(properties?.population)}</div>
				<div><strong>Median Age:</strong> {properties?.median_age || "N/A"}</div>
				<div><strong>Land Area:</strong> {formatNumber(properties?.land_area)} km²</div>
			</div>
		);
	};

	const arcColor = ["#00FF80", "#00FF80"];
	const arcsData =
		relatedPoints.length > 0 && selectedPoint
			? relatedPoints.filter((rp) => rp !== selectedPoint).map((relatedPoint) => ({
				startLat: relatedPoint.geometry.coordinates[1],
				startLng: relatedPoint.geometry.coordinates[0],
				endLat: selectedPoint.geometry.coordinates[1],
				endLng: selectedPoint.geometry.coordinates[0],
			}))
			: [];

	// Final order: News, Conflicts, Economic, Environment, Cyber, Infrastructure, Related, Selected
	const legendContainerStyle = {
		display: "flex",
		alignItems: "center",
		marginTop: "3px",
		fontSize: "8px",
		flexWrap: "wrap",
		justifyContent: "center",
		gap: "8px",
	};
	const legendItemStyle = {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
	};
	const colorBoxStyle = (color) => ({
		width: "16px",
		height: "16px",
		backgroundColor: color,
		marginRight: "3px",
	});

	return (
		<div style={{ position: "relative" }}>
			<div style={hudStyles.container}>
				<div style={{ fontSize: "15px" }}>Earth Watch</div>
				<div style={legendContainerStyle}>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("#FFA500")}></div>News
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("red")}></div>Conflicts
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("yellow")}></div>Economic
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("#87CEFA")}></div>Environment
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("green")}></div>Cyber
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("brown")}></div>Infrastructure
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("purple")}></div>Related
					</div>
					<div style={legendItemStyle}>
						<div style={colorBoxStyle("#FF5F1F")}></div>Selected
					</div>
				</div>
			</div>

			<Globe
				ref={globeRef}
				globeImageUrl="/world.topo.bathy.200412.3x5400x2700-2.jpg"
				backgroundColor="black"
				pointsData={[
					...newsData,
					...conflictData,
					...economicData,
					...environmentData,
					...cyberData,
					...infrastructureData,
					...relatedPoints,
					...data
				]}
				arcsData={arcsData}
				arcColor={() => arcColor}
				arcDashLength={1}
				arcDashGap={0}
				arcStroke={1.5}
				pointLat={(d) => d.geometry?.coordinates[1]}
				pointLng={(d) => d.geometry?.coordinates[0]}
				pointColor={(d) => {
					if (d.selected) return "#FF5F1F";
					if (
						d.properties?.background_paragraph &&
						d.properties?.overview_of_the_hack &&
						d.properties?.damage_assessments_and_fallout &&
						d.properties?.security_measures
					) return "green";
					if (d.properties?.event_name && d.properties?.overview) return "#87CEFA";
					if (d.properties?.is_main_conflict) return "red";
					if (d.properties?.paragraph && d.properties?.title) return "#FFA500";
					if (d.properties?.economic_title && d.properties?.details) return "yellow";
					if (d.properties?.infrastructure_title && d.properties?.summary) return "brown";
					if (relatedPoints.includes(d)) return "purple";
					return "blue";
				}}
				pointRadius={(d) => (d.properties?.is_main_conflict ? 0.5 : 0.7)}
				pointAltitude={(d) => (!d.properties?.is_main_conflict ? (d.selected ? 0.06 : 0.04) : 0.08)}
				enablePointerInteraction={true}
				onPointClick={handlePointClick}
			/>

			{selectedPoint && (
				<div style={popupStyles.container}>
					<div style={popupStyles.header}>
						{selectedPoint.properties?.title ||
							selectedPoint.properties?.country ||
							selectedPoint.properties?.event_name ||
							selectedPoint.properties?.economic_title ||
							selectedPoint.properties?.infrastructure_title ||
							"Unknown Location"}
					</div>
					<div style={popupStyles.content}>{renderContent()}</div>
					<div style={popupStyles.footer}>
						<button
							onClick={() => {
								setSelectedPoint(null);
								setRelatedPoints([]);
								setContextParagraph("");
								setRelatedHeader("");
								setMainConflictPoint(null);
							}}
							style={popupStyles.closeButton}
						>
							Close
						</button>
					</div>
				</div>
			)}
		</div>
	);
}

export default Earth;